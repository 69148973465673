var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "knowledge-base-article" } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { lg: "3", md: "5", order: "2", "order-md": "1" } },
            [
              _c(
                "b-card",
                [
                  _c(
                    "h6",
                    { staticClass: "kb-title" },
                    [
                      _c("feather-icon", {
                        staticClass: "mr-50",
                        attrs: { icon: "InfoIcon", size: "20" }
                      }),
                      _vm._v(" Related Questions ")
                    ],
                    1
                  ),
                  _c(
                    "b-list-group",
                    { staticClass: "list-group-circle mt-1" },
                    _vm._l(_vm.kb_question.relatedQuestions, function(
                      que,
                      index
                    ) {
                      return _c(
                        "b-list-group-item",
                        {
                          key: index,
                          staticClass: "text-body",
                          attrs: { href: "javascript:void(0)" }
                        },
                        [_vm._v(" " + _vm._s(que.question) + " ")]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { lg: "9", md: "7", order: "1", "order-md": "2" } },
            [
              _c(
                "b-card",
                [
                  _c(
                    "b-card-title",
                    { staticClass: "mb-1" },
                    [
                      _c("feather-icon", {
                        attrs: { icon: "SmartphoneIcon", size: "21" }
                      }),
                      _vm._v(" " + _vm._s(_vm.kb_question.title) + " ")
                    ],
                    1
                  ),
                  _c("b-card-text", { staticClass: "mb-2" }, [
                    _vm._v(
                      " Last updated on " +
                        _vm._s(_vm.kb_question.lastUpdated) +
                        " "
                    )
                  ]),
                  _c("div", {
                    staticClass: "question-content",
                    domProps: { innerHTML: _vm._s(_vm.kb_question.content) }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }